html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}

ul li {
  list-style: none;
}

input {
  background: none;
  outline: none;
  border: 0px;
}

.container {
  width: 1200px;
  //box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.substring {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.substring2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.clear {
  clear: both;
  height: 0px;
  font-size: 0px;
  line-height: 0px;
}

.border-shadow:hover {
  box-shadow: #ccc 0 0 7px;
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  background: none;
}

.iconfont {
  color: #999;
  margin-right: 5px;
}

.ant-pagination {
  text-align: center;
  margin-top: 50px;
  width: 100%;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-top: 20px;
  margin-left: 0;
}

.braft-output-content img,
.braft-output-content video {
  width: 100%;
}

a {
  text-decoration: none;
}
